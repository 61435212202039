import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/SEO'
const CaseStudyPage = ({ data }) => {
  const { post } = data
  if (!post)
    return (
      <div className="h-[80vh] flex items-center justify-center w-full">
        <div className="flex mx-auto items-center justify-center space-x-2 animate-pulse">
          <div className="w-8 h-8 bg-blue-400 rounded-full"></div>
          <div className="w-8 h-8 bg-blue-400 rounded-full"></div>
          <div className="w-8 h-8 bg-blue-400 rounded-full"></div>
          <div className="w-8 h-8 bg-blue-400 rounded-full"></div>
        </div>
      </div>
    )
  return (
    <>
      <Seo
        title={post?.title?.rendered}
        description={post?.title?.rendered}
        image={post.jetpack_featured_media_url}
      />
      <div className="mx-auto mt-8 w-full flex flex-col items-center justify-center">
        <div className="max-w-6xl w-full flex flex-col items-center justify-center mt-16 blog-container">
          <h1 className="text-2xl md:text-5xl max-w-3xl font-black text-teal-900  font-display">
            {post?.title?.rendered}
          </h1>
          {post && (
            <div
              className="max-w-3xl text-start font-paragraph text-xl leading-[35px] my-8"
              dangerouslySetInnerHTML={{ __html: post?.content?.rendered }}
            ></div>
          )}
        </div>
      </div>
    </>
  )
}

export default CaseStudyPage

export const pageQuery = graphql`
  query ($slug: String!) {
    post: wpCaseStudies(slug: { eq: $slug }) {
      title {
        rendered
      }
      date(formatString: "MMM DD YYYY")
      author
      content {
        rendered
      }
      jetpack_featured_media_url
      slug
    }
  }
`
